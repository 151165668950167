import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';

import { AppConfig } from '../core/config/app.config';
import { AppState, getState } from '../store/models/app.state';
import { ProjectModel } from './project.model';
import { PharHttp } from '../core/http/http-client.service';
import { IStudyMatrixItem } from '../study-matrix/study-matrix-editor/study-matrix-item.interface';
import { ISite } from './sites-and-study-team/study-and-study-team-manage/site.interface';
import { ITeamMember } from './sites-and-study-team/study-and-study-team-manage/team-member.interface';
import { IEventComment } from '../events/event-comments/event-comment.interface';
import { IProjectMatrixItemComment } from '../study-matrix/project-matrix-item-comment.interface';

@Injectable()
export class ProjectService {
  constructor(
    private http: PharHttp,
    private config: AppConfig,
    private store: Store<AppState>
  ) {
  }

  ROLES = [
    'rol_0Xanx1sTadrsSpnU',
    'rol_fC27GkIMDq8vXcsN',
    'rol_yRnnu9tx2c1vL3es',
    'rol_ob8A5vD0fnXmTUoQ',
    'rol_W4MbUQuNi3tYxOht',
    'rol_1L6hhr3q5rQwqwgH',
    'rol_3yPvpkI8uJa6OIWZ',
    'rol_uBWzWXnZpPjDF3e1',
    'rol_VNudpwrAooOOW1ir'
  ];

  MOCK_TEAM_MEMBERS: ITeamMember[] = new Array(5).fill(null).map((_, index) => {
    const memberNumber = index + 1;
    return {
      id: memberNumber,
      firstName: `First Name ${memberNumber}`,
      lastName: `Last Name ${memberNumber}`,
      middleName: `Middle Name ${memberNumber}`,
      title: `Title ${memberNumber}`,
      degree: `Degree ${memberNumber}`,
      email: `teamMember${memberNumber}@pharmatrail.com`,
      countryCode: '+359',
      phoneNumber: new Date().getTime() + memberNumber,
      createdAt: '2024-05-29T12:16:41.441Z',
      modifiedAt: null,
      roleTypes: this.ROLES.slice(index, index + 1 + index % 3),
      isActive: !!(memberNumber % 2),
    };
  });

  MOCK_SITES = [
    {
      "name": "Site1",
      "code": "SITE1ID",
      "street": "adres1",
      "street_ln": "adress2",
      "city": "Asenovgrad",
      "state": "Plovdiv",
      "postal_code": "4230",
      "country_id": 34,
      "country_code": "+359",
      "phone": 888888,
      "email": "email@ab.bg",
      "timezone_id": 303,
      "language_id": 24,
      created_at: '',
      is_active: true,
      modified_at: '',
      investigator: 'Site 1 Investigator',
      id: 1,
      teamMembers: this.MOCK_TEAM_MEMBERS.slice(0, 2),
    },
    {
      "name": "Site2",
      "code": "SITE2ID",
      "street": "adres2",
      "street_ln": "adress3",
      "city": "Another city",
      "state": "Some State",
      "postal_code": "4445",
      "country_id": 235,
      "country_code": "+444",
      "phone": 111111,
      "email": "af@abv.af",
      "timezone_id": 5,
      "language_id": 1,
      created_at: '',
      is_active: true,
      modified_at: '',
      investigator: 'Site 2 Investigator',
      id: 2,
      teamMembers: this.MOCK_TEAM_MEMBERS.slice(2, 3),
    },
    {
      "name": "Site3",
      "code": "SITE3ID",
      "street": "adres3",
      "street_ln": "adress4",
      "city": "4 City",
      "state": "4 State",
      "postal_code": "55555",
      "country_id": 233,
      "country_code": "+11",
      "phone": 1122222,
      "email": "test@email.com",
      "timezone_id": 110,
      "language_id": 169,
      created_at: '',
      is_active: true,
      modified_at: '',
      id: 3,
      investigator: 'Site 3 Investigator',
    },
    {
      "name": "Inactive site",
      "code": "SITE4ID",
      "street": "address44",
      "street_ln": "address44 street",
      "city": "Some City",
      "state": "Some State",
      "postal_code": "98123",
      "country_id": 235,
      "country_code": "+93",
      "phone": 311222223,
      "email": "site4@email.com",
      "timezone_id": 111,
      "language_id": 170,
      created_at: '',
      is_active: false,
      modified_at: '',
      id: 4,
      investigator: 'Site 4 Investigator',
    },
    {
      "name": "Inactive site 2",
      "code": "SITE5ID",
      "street": "address44",
      "street_ln": "address44 street",
      "city": "Some City",
      "state": "Some State",
      "postal_code": "98123",
      'country_id': null,
      "country_code": '',
      "phone": 311222223,
      "email": "site5@email.com",
      "timezone_id": 111,
      "language_id": 170,
      created_at: '',
      is_active: false,
      modified_at: '',
      id: 5,
      investigator: 'Site 5 Investigator',
    }

  ]

  MOCK_STUDY_ADMINS: ITeamMember[] = [
    {
      id: 1,
      firstName: 'John',
      middleName: 'Johnson',
      lastName: 'Doe',
      email: 'a@a.com',
      roleTypes: ['rol_fC27GkIMDq8vXcsN', 'rol_RwoTadMVnSYHw81Z'],
      phoneNumber: 11111111,
      degree: 'PhD',
      title: 'Dr.',
      countryCode: '+359',
      isActive: false,
    },
    {
      id: 2,
      firstName: 'Jane',
      middleName: 'Jannson',
      lastName: 'Doe',
      email: 'b@b.com',
      roleTypes: ['rol_ob8A5vD0fnXmTUoQ'],
      phoneNumber: 22222222,
      degree: 'PhD',
      title: 'Dr.',
      countryCode: '+61',
      isActive: false,
    },
    {
      id: 3,
      firstName: 'Michael',
      middleName: 'Mickson',
      lastName: 'Adams',
      email: 'c@c.com',
      roleTypes: ['rol_1L6hhr3q5rQwqwgH', 'rol_B8MCV3DxrrWhVcJ1'],
      phoneNumber: 33333333,
      degree: 'PhD',
      title: 'Md.',
      countryCode: '+43',
      isActive: false,
    },
    {
      id: 4,
      firstName: 'Mark',
      lastName: 'Evan',
      email: 'd@d.com',
      roleTypes: ['rol_2hVMeN8mynphikDC'],
      phoneNumber: 56778,
      countryCode: '+44',
      middleName: '',
      degree: '',
      title: '',
      isActive: false,
    }

  ]

  create(project: ProjectModel) {
    const projectBody = { ...project };
    delete projectBody.id;
    delete projectBody.datasetId;

    projectBody.organizationId = getState(this.store).user.profile.organizationId;
    projectBody.userIdCreated = getState(this.store).user.profile.userId;
    return this.http.post(this.config.buildUri('project') + '/Project', projectBody);
  }


  getProjectList(): Observable<ProjectModel[]> {
    return this.http.get(this.config.buildUri('project') + '/Projects');
  }

  deleteProject(id: number): Observable<any> {
    return this.http.delete(this.config.buildUri('project') + '/Project/?id=' + id);
  }

  searchById(id: number) {
    return this.http.get(this.config.buildUri('project') + '/ProjectById/?id=' + id);
  }

  update(project: ProjectModel): Observable<ProjectModel> {
    project.userIdUpdated = getState(this.store).user.profile.userId;
    return this.http.put(this.config.buildUri('project') + '/Project', project);
  }

  getRecordFormsPerProject(projectId: number, datasetId: number) {
    return this.http.get(`${this.config.buildUri('form')}/RecordFormsPerProject?projectId=${projectId}&datasetId=${datasetId}`);
  }

  // TODO Check if the endpoint is correct.
  getExportProjectJson(id: number): Observable<string> {
    return this.http.get(this.config.buildUri('project') + '/ExportJson/?projectId=' + id);
  }

  getProjectMatrixByProjectId(projectId: number): Observable<IStudyMatrixItem[]> {
    return this.http.get(this.config.buildUri('projectMatrix') + `/ProjectMatrixByProjectId?projectId=${projectId}`)
  }

  createProjectMatrixItem(data: IStudyMatrixItem): Observable<IStudyMatrixItem> {
    return this.http.post(this.config.buildUri('projectMatrix') + '/ProjectMatrix', data);
  }

  deleteProjectMatrixItem(id: number): Observable<any> {
    return this.http.delete(this.config.buildUri('projectMatrix') + `/ProjectMatrix?projectMatrixId=${id}`);
  }

  updateProjectMatrixItem(data: IStudyMatrixItem): Observable<any> {
    return this.http.put(this.config.buildUri('projectMatrix') + `/ProjectMatrix`, data);
  }

  duplicateProject(id: number): Observable<ProjectModel> {
    return this.http.post(this.config.buildUri('project') + '/DuplicateProject?projectId=' + id, {});
  }

  amendProject(id: number): Observable<ProjectModel> {
    return this.http.post(this.config.buildUri('project') + '/AmendProject?projectId=' + id, {});
  }

  getSites(): Observable<ISite[]> {
    // return this.http.get(this.config.buildUri('project') + '/Sites');
    return of(this.MOCK_SITES)
  }

  getSiteById(id: number): Observable<ISite> {
    return of(this.MOCK_SITES.find(site => site.id === id));
  }

  getTeamMembers(): Observable<ITeamMember[]> {
    return of(this.MOCK_TEAM_MEMBERS);
  }

  getStudyAdmins(studyId: number): Observable<ITeamMember[]> {
    return of(this.MOCK_STUDY_ADMINS);
  }

  deleteStudyAdmin(id: number) {
    // @TODO delete study admin API
    return of(id);
  }

  getUserById(id: number): ITeamMember {
    return this.MOCK_STUDY_ADMINS.find(user => user.id === id);
  }

  getProjectEventComments(projectEventId: number): Observable<IEventComment[]> {
    return this.http.get(this.config.buildUri('projectEventComments') + `/ProjectEventComments?projectEventId=${projectEventId}`)
  }

  createProjectEventComment(payload: Partial<IEventComment>): Observable<IEventComment> {
    return this.http.post(this.config.buildUri('projectEventComments') + `/CreateProjectEventComments`, payload);
  }

  updateProjectEventComment(payload: IEventComment): Observable<IEventComment> {
    return this.http.put(this.config.buildUri('projectEventComments') + `/ProjectEventComments`, payload);
  }

  deleteProjectEventComment(commentId: number): Observable<IEventComment> {
    return this.http.delete(this.config.buildUri('projectEventComments') + `/ProjectEventComments?projectEventCommentsId=${commentId}`)
  }

  resolveProjectEventComment(commentId: number): Observable<IEventComment> {
    return this.http.post(this.config.buildUri('projectEventComments') + `/MarkCommentAsResolved?projectEventCommentId=${commentId}`, {})
  }

  getProjectMatrixItemsCommentsCounters(projectId: number) {
    return this.http.get(this.config.buildUri('projectMatrixComments') + `/ProjectMatrixCommentCount?projectId=${projectId}`);
  }

  getProjectMatrixItemComments(projectMatrixId: number) {
    return this.http.get(this.config.buildUri('projectMatrixComments') + `/ProjectMatrixComments?projectMatrixId=${projectMatrixId}`)
  }

  updateProjectMatrixItemComments(payload: IProjectMatrixItemComment) {
    return this.http.put(this.config.buildUri('projectMatrixComments') + `/ProjectMatrixComments`, payload)
  }

  deleteProjectMatrixItemComments(commentId: number) {
    return this.http.delete(this.config.buildUri('projectMatrixComments') + `/ProjectMatrixComments?projectMatrixCommentsId=${commentId}`)
  }

  resolveProjectMatrixItemComments(commentId: number): Observable<any> {
    return this.http.post(this.config.buildUri('projectMatrixComments') + `/MarkCommentAsResolved?projectMatrixCommentId=${commentId}`)
  }

  createProjectMatrixItemComments(payload: any): Observable<any> {
    return this.http.post(this.config.buildUri('projectMatrixComments') + `/CreateProjectMatrixComments`, payload)
  }


}

